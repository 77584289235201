<template>
    <a-result status="500" title="500" sub-title="服务发生异常，无法完成请求">
        <template #extra>
            <a-button type="primary" @click="Back">
                返回
            </a-button>
        </template>
    </a-result>
</template>
<script>
    import Vue from 'vue'
    export default {
        name: "",
        data() {
            return {
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            Back() {
                Vue.global.NavigateTo("HomeIndex");
            }
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>